.banner {
  position: relative;
  overflow: hidden;
}
/*.owl-item:not(.active) + .owl-item.active{
  background: red;
}*/
.thumbnailSlider{
  position: relative;
  z-index: 2;
}
.bgSliderWrapper{
  position: absolute;
  top:0;
  left:0;
  z-index: 1;
  width: 100%;
  height: 100%;
  div{
    height: 100%;
  }
  .slides{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
  }
}
.mainbanner {
  padding: 100px 0;
  .texter{
    position: relative;
    z-index: 2;
  }
  label {
    font-weight: $boldweight;
    font-size: 18px;
  }
  h5 {
    color: $text;
    font-size: 70px;
    text-transform: uppercase;
    font-weight: $thinweight;
    margin: 0;
    line-height: 70px;
    strong {
      font-weight: $blackweight;
      color: $maincolor;
    }
  }
  p {
    font-weight: $boldweight;
    margin-bottom: 30px;
  }
  figure {
    position: absolute;
    right: 10%;
    height: 85%;
    z-index: 8;
    bottom: 0;
    &:after {
      width: 800px;
      height: 800px;
      background: $maincolor;
      border: 30px solid $white;
      border-radius: 100px;
      @include rotate(45);
      content: "";
      display: block;
      position: absolute;
      left: -42%;
      bottom: -90%;
      z-index: 1;
    }
    &:before {
      width: 500px;
      height: 40px;
      @include rotate(45);
      border-radius: 80px;
      content: "";
      display: block;
      position: absolute;
      background: $maincolor;
      bottom: 160px;
      right: -420px
    }
    img {
      height: 100%;
      width: auto;
      position: relative;
      z-index: 2;
    }
  }
}

.mainContent {
  background: $maincolor;
  width: 260px;
  height: 260px;
  padding: 20px;
  color: $white;
  position: relative;

  .spacing {
    span {
      position: absolute;
      height: 50%;
      width: 50%;
      background: rgba(255, 255, 255, 0.5);
      &.spaceright1 {
        right: -50%;
        bottom: 0;
      }
      &.spaceright2 {
        right: -100%;
        background: $secondary;
        opacity: 0.8;
        top: 0;
      }
      &.spaceright3 {
        right: -150%;
        bottom: 0;
      }
      &.spaceright4 {
        right: -200%;
        top: 0;
      }
      &.spaceleft1 {
        left: -50%;
        bottom: 0;
      }
      &.spaceleft2 {
        left: -100%;
        background: $secondary;
        opacity: 0.8;
        top: 0;
      }
      &.spaceleft3 {
        left: -150%;
        bottom: 0;
      }
      &.spaceleft4 {
        left: -200%;
        top: 0;
      }
    }
  }
  .slides {
    span {
      font-size: 70px;
      opacity: 0.1;
      line-height: 90px;
      font-weight: $blackweight;
      font-family: $headings-font;

    }
    h5 {
      font-size: 18px;
      line-height: 30px;
      letter-spacing: -0.1px;
      font-family: $base-font;
      margin: 0;
      padding-right: 20%;
      font-weight: $boldweight;
    }
    .owl-controls {
      .owl-pagination {
        text-align: left;
        .owl-page {
          margin-right: 15px;
          span {
            margin: 0;
            width: 5px;
            height: 5px;
            background: $white;
          }
        }
      }

    }

  }

}

.actionListing {
  aside {
    display: inline-block;
    background: $white;
    width: 33.33%;
    height: 100%;
    float: left;
    padding: 25px;
    position: relative;
    //padding-right: 70px;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.14);
    h3 {
      text-transform: uppercase;
      color: $text;
      font-weight: $blackweight;
      font-size: 24px;
      margin-bottom: 10px;
      padding-right: 40px;

    }
    p {
      margin-bottom: 0;
    }
    &:before {
      display: block;
      content: "";
      width: 86px;
      position: absolute;
      right: 20px;
      top: 20px;
      opacity: 0.05;
      height: 90px;
    }
    &.list1 {
      &:before {
        background: url(images/list1.png) no-repeat;
      }
    }
    &.list2 {
      &:before {
        background: url(images/list2.png) no-repeat;
      }
    }
    &.list3 {
      &:before {
        background: url(images/list3.png) no-repeat;
      }
    }
  }
}

.virtualTour {
  background: url(images/virtualBg.png) no-repeat center $maincolor;
  background-size: cover;
  color: $white;
  height: 100%;
  position: relative;
  cursor: pointer;
  padding-right: 40px;
  padding-left: 130px;
  @include transition;

  img {
    position: absolute;
    left: 50px;
    top: 50%;
    @include transition;
    @include translator($x: 0, $y: -50%);
  }
  &:hover {
    background-color: $secondary;
    img {
      //@include rotate(-180)
    }
  }
  /*a{
    color: $white;
    display: block;
    padding: 50px 50px 50px 130px;
  }*/
  h2 {
    font-size: 35px;
    text-transform: uppercase;
    font-weight: $blackweight;
    margin: 0;
    margin-top: 25px;
    line-height: 30px;
  }
  span {
    font-size: 16px;
    font-family: $headings-font;
    font-weight: $boldweight;
    text-transform: uppercase;
  }
  p {
    font-size: 14px;
    font-style: italic;
    margin-bottom: 0;
  }
}

#aboutCollege {
  .inner{
    padding-right: 80px;
    padding-top: 80px;
    padding-bottom: 80px;
    background: url(images/university.png) no-repeat right top;
    background-size: 260px;
  }
  .category {
    display: block;
    height: 175px;
    background-size: cover;
    position: relative;
    margin-bottom: 10px;

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: $maincolor;
      opacity: 0.7;
      position: absolute;
      top: 0;
      left: 0;
      @include transition;
    }
    h3 {
      color: $white;
      font-size: 45px;
      text-transform: uppercase;
      font-weight: $blackweight;
      margin-bottom: 0;
      line-height: 30px;
      position: absolute;
      right: 25px;
      bottom: 0;
      z-index: 2;
      @include transition;
    }
    &:hover {
      &:before {
        background: $secondary;
      }
      h3{
        right: 50px;
      }
    }
  }
}
#noticeBox{
  background: $bg;
}

.noticeListing{
  .sectionTitle{
    .labeling{
      line-height: 33px;
      font-weight: $boldweight;
      font-size: 16px;
      padding-right: 30px;
    }
  }
  .col{
    margin-bottom: 5px;
  }
  figure{
    width: 50%;
    float: left;
  }
  .content{
    width: 50%;
    float: right;
    height: 100%;
    background: $white;
    padding: 50px 40px 0;
    .date{
      font-size: 10px;
      letter-spacing: 2.4px;
      font-family: $headings-font;
      text-transform: uppercase;
      color: $grey;
      margin-bottom: 15px;
      font-weight: $boldweight;
    }
    a{
      color: $text;
      &:hover{
        color: $maincolor;
      }
    }
    h4{
      margin-bottom: 0;
      font-size: 21px;
    }
  }
  .styled{

    .col:nth-child(2){
     &:after{
       width: 100px;
       height: 100px;
       background: $white;
       content: "";
       display: block;
       position: absolute;
       right:-85px;
       top:-100px
     }
    }
    &.colored{
      .col:nth-child(1){
        &:before{
          width: 100px;
          height: 100px;
          background: $secondary;
          content: "";
          display: block;
          position: absolute;
          left:-85px;
          opacity: 0.95;
          bottom:2px
        }
      }
      .col:nth-child(2){
        &:after{
          top:0;
          background: $maincolor;
        }
      }
    }
  }
}

.middleMenu{
  display: none;
  ul{
    li{
      flex: 1 1 auto !important;
      text-align: center;
      @include transition;
      a{
        display: block;
        //background: $maincolor;
        padding: 20px 0;
        color: $white;
        font-size: 23px;
        font-weight: $blackweight;
        font-family: $headings-font;
      }
      &:nth-child(1){background: $maincolor}
      &:nth-child(2){background: #0b6ac2}
      &:nth-child(3){background: #0b73d4}
      &:nth-child(4){background: #0d7ce2}
      &:nth-child(5){background-image: linear-gradient(to right, #2495fd , #fc5157); }
      &:nth-child(6){background: #fa3940}
      &:nth-child(7){background: #ef252c}
      &:nth-child(8){background: $secondary}
      &:hover{
        background: $maincolor;
      }
    }
  }
}

#missionVision{
  background: url(images/visionBg.jpg) no-repeat right center;
  background-size:35%;
  figure{
    min-height: 70px;
  }
  h4{margin-bottom: 5px;}
}

#testimonials{
  background-image: url(images/clients.jpg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 32%;
  .bottomPart{
    padding-left: 20%;
    padding-right: 50px;
  }
}
.clientsSlider{
  .saying{
    color: $text;
    height: 100%;
    padding: 15px;
    cursor: pointer;
    .inner{
      @include transition();
      background: $white;
      background : rgba(255,255,255,0.2);
      padding: 25px;
      color: $white;
      height: 100%;
    }
    p{
      min-height:240px;
    }
    .details{
      figure{
        float: left;
        width: 60px;
        height: 60px;
        border-radius: 50px;
        margin-right: 10px;
        overflow: hidden;
      }

      h5{
        margin-bottom: 0;
        padding-top: 10px;
        font-size: 21px;
      }
      label{
        font-family: $headings-font;
        font-style: italic;
      }
    }
  }
  .active-first{
    .saying{
      .inner{
        background: $white;
        color: $text;
      }
    }
  }
}

//registration

#aboutCollege-back {
  background: #065baa;
}

.googleMap{
  line-height: 0;
  font-size: 0;
  iframe{
    position: relative;
    width: 100% ;
  }
}

.callToAction{
  .emptyspace{
    background: $maincolor;
  }
}