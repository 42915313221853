html, body {
  width: 100%;
}

body {
  @include font($f: $base-font, $s: $font-size-base, $w: $normalweight);
  color: $text;
  //background: #f5c26f;
  background: $white;
  margin: 0;
  line-height: 30px;
  padding: 0;
}

ul, li {
  text-decoration: none;
  list-style: none;
  margin-bottom: 0 !important;
  padding: 0;
}

.wrapper {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto !important;
}

.btn {
  background: $maincolor;
  color: $white;
  border: none;
  text-transform: uppercase;
  font-size: 15px;
  cursor: pointer;
  letter-spacing: 0.5px;
  font-weight: $boldweight;
  font-family: $headings-font;
  border-radius: 50px;
  padding: 8px 25px;
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.08);
  margin-right: 10px;
  @include transition;
  &:hover {
    background: $secondary;
    color: $white;
  }
}

.darkTheme {
  background: $maincolor;
  color: $white;
}

.bg-light {
  background: $bg;
}

.mainTitle {
  font-size: 30px;
  font-weight: $thinweight;
  color: $text;
  &.small {
    font-size: 25px;
  }
  span {
    color: $maincolor;
    font-weight: $blackweight;

    &.block {
      display: block;
    }
    &.upper {
      text-transform: uppercase;
    }
  }
  &.light {
    color: $white;
    span {
      color: $white;
    }
  }
}

.basePadding {
  padding-top: 80px;
  padding-bottom: 80px;
  &.smallPadding {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

#innerBanner {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;

  border-bottom: 3px solid $maincolor;
  margin-top: -60px;
  .bannerWrapper {
    position: relative;
    min-height: 300px;
  }
  h1 {
    margin-bottom: auto;
    display: inline-block;
    background: $maincolor;
    color: $white;
    padding: 10px 20px 5px;
    font-size:24px;
    font-weight: $boldweight;
    position: absolute;
    bottom: 0;
  }
  .rightBar {
    position: absolute;
    right: 0;
    bottom: 0;
    text-align: right;
  }
  .enquirybtn {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: $boldweight;
    color: $white;
    padding: 10px 20px 7px;
    font-family: $headings-font;
    display: inline-block;
    @include transition;
    &:hover {
      background-image: linear-gradient(to right, $maincolor, $maincolor);
    }
  }
  .breadcrumbBar {
    line-height: 38px;
    text-align: right;
    font-size: 14px;
    li {
      display: inline-block;
      a {
        color: $maincolor;
        &:hover {
          text-decoration: underline;
        }
      }
      &:before {
        content: ">";
        display: inline-block;
        margin: 0 7px 0 5px;
      }
      &:first-child {
        &:before {
          display: none;
        }
      }
    }
  }
}

.gradient {
  background-image: linear-gradient(to right, $maincolor, #d71920);
  color: $white;
}

.contentArea {
  ul, ol {
    padding-left: 25px;
    li {
      margin-bottom: 5px;
      list-style: disc;
      line-height: 24px;
    }
  }
}

.styledHeading {
  color: $text;
  font-size: 25px;
  line-height: 45px;
  margin-bottom: 0;
  font-weight: $thinweight;
  b {
    font-weight: $boldweight;
    color: $maincolor;
  }
}

.container-small {
  max-width: 700px;
}

.responsive-TabAccordion {
  .nav-tabs {
    display: none;
  }

  @media(min-width: 768px) {
    .nav-tabs {
      display: flex;
    }

    .card {
      border: none;
    }

    .card .card-header {
      display: none;
    }

    .card .collapse {
      display: block;
    }
  }

  @media(max-width: 767px) {
    .tab-pane {
      display: block !important;
      opacity: 1;
      margin-bottom: 8px;
    }
    .card-header {
      padding: 0;
      background: none;
      border: none;
      a {
        padding: .75rem 1.25rem;
        background: $maincolor;
        color: $white;
        display: block;
        width: 100%;
      }
    }
    .card-body {
      h3 {
        display: none;
      }
    }
  }
}

#accordion {
  .card {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #e1e1e1;
    padding: 15px 0;
    .card-header {
      padding: 0 0 0;
      border: none;
      background: none;
      position: relative;

      a {
        font-size: 18px;
        font-weight: $semibold;
        display: block;
        line-height: 26px;
        position: relative;
        padding-left: 40px;
        color: $maincolor;
        &:before {
          font-family: FontAwesome;
          font-weight: 900;
          content: "\002D";
          position: absolute;
          font-size: 28px;
          left: 0;
          top: 0;

        }
        &.collapsed {
          color: $text;
          &:before {
            content: "\002B";
          }
        }
      }
    }
    .card-body {
      padding: 15px 0 15px 40px;
      line-height: 30px;
      font-style: italic;
      font-size: 15px

    }
  }
}

.page-content {
  h4 {
    font-weight: $semibold;
    font-size: 20px;
  }
}

input[type="text"],
input[type="email"],
input[type="search"],
input[type="number"],
input[type="email"],
textarea,
select {
  background: #f5f5f5;
  border: none;
  border-radius: 0;
  padding: 8px 20px;
  font-style: italic;
  width: 100%;
  margin-bottom: 13px;
}
textarea{
  height: 150px;
}
.shadow {
  background: $white;
  box-shadow: 0 0 43px rgba(0, 0, 0, 0.09) !important;
}

.paginationSection {
  text-align: center;
  padding: 50px;
  ul {
    li {
      display: inline-block;
      a, span {
        background: $white;
        box-shadow: 0 0 43px rgba(0, 0, 0, 0.09) !important;
        color: $text;
        padding: 10px 15px;
        font-family: $headings-font;
        display: block;
        margin: 2px;
        font-size: 14px;
        font-weight: $boldweight;
      }
      span, a:hover{
        background: $maincolor;
        color: $white;
      }
    }
  }
}

.sidebar {
  .sidebarTitle {
    margin-bottom: 10px;
    font-size: 22px;
  }
  aside {

    margin-top: 50px;
    font-family: $headings-font;
    font-size: 14px;
    &:first-child {
      margin-top: 0;
    }
    &.borders {
      background: #f2f2f2;
      padding: 30px;
      border-top: 5px solid $maincolor;
    }
  }
  .latestNews {
    li {
      border-top: 1px solid rgba(0, 0, 0, 0.15);
      &:first-child {
        border-top: none;
      }
      a {
        color: $text;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        padding: 8px 0;
        text-overflow: ellipsis;
        &:hover {
          color: $maincolor;
        }
      }
    }
  }
  .advertise{
    img{
      margin-bottom: 30px;
    }
  }

}


.singlePageHeading{
  background: $maincolor;
  color: $white;
  h1{
    margin-bottom: 0;
    font-size: 30px;
  }
}

.contents, .contentArea{
  strong, b{
    margin-bottom: 5px;
    font-size: 18px;
    display: inline-block;
  }
  ul,ol{
    padding-left: 25px;
    margin-bottom: 20px !important;
    li{
      list-style: disc;
      font-size: 15px;
      line-height: 30px;
      margin-bottom: 20px;
    }
  }
}

.globalTab{
  .card-header{
    display: none;
  }
  .nav-tabs{
    border:none;
    li{
      font-size: 20px;
      font-weight: $boldweight;
      font-family: $headings-font;
      display: inline-block;
      margin-right: 5px;
      a{
        color: $text;
        background: #dadada;
        display: block;
        padding: 8px 15px;
      }
      &.active, &:hover{
        a{
          background: $maincolor;
          color: $white;
        }
      }
    }
  }
  .tab-content{
    border:2px solid #dadada
  }
}

.masonry { /* Masonry container */
  column-count: 4;
  column-gap: 1em;
}

.item { /* Masonry bricks or child elements */
  //background-color: #eee;
  display: inline-block;
  margin: 0 0 1em;
  width: 100%;
}

.greybg{
  background-color: #f5f5f5;
}

.textImageSection{
  .container{
    position: relative;
    z-index: 3;
  }
  .imageText{
    background-size: 50%;
    background-position: left center;
    background-repeat: no-repeat;
    position: relative;
    &:before{
      content: "";
      display: block;
      position: absolute;
      right: 50%;
      top:0;
      width: 30%;
      height: 100%;
      opacity: 0.85;
      z-index: 2;
      background-image: linear-gradient(to right, transparent , #f5f5f5);
    }
    &:after{
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top:0;
      width: 50%;
      height: 100%;
      background:#f5f5f5 ;
      opacity: 0.9;
      z-index: 1;
    }
  }
  .textImage{
    background-size: 50%;
    background-position: right center;
    background-repeat: no-repeat;
    position: relative;
    &:before{
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      top:0;
      width: 30%;
      height: 100%;
      opacity: 0.85;
      z-index: 2;
      background-image: linear-gradient(to right,  #fff, transparent);
    }
    &:after{
      content: "";
      display: block;
      position: absolute;
      right: 0;
      top:0;
      width: 50%;
      height: 100%;
      background:#f5f5f5 ;
      opacity: 0.9;
      z-index: 1;
    }
  }
}

.loginContent{
  .form-group {
    margin-bottom: 7px;
    input.form-control {
      margin-bottom: 0;
    }
  }
}

#courseSingle{
  #accordion {
    .card {
      padding: 0;
      .card-body{
        padding:0;
      }
    }
    .accordingHeading{
      font-size: 18px;
      text-transform: uppercase;
      padding: 15px ;
      letter-spacing: 1px;
      color: $maincolor;
      cursor: pointer;
      @include transition;
      &:hover{
        background: #f1f1f1;
      }
    }
    table.table{
      font-size: 15px;
      font-style: normal;
      thead{
        tr{
          th{
            background: #e1e1e1;
          }
        }
      }
    }
  }
}