/*--------------------------------------------------------------
# Clearings
--------------------------------------------------------------*/
.clear {clear: both;display: block;}
.clearfix {
  &:before{display: block}
  &:after {content: ".";display: block;height: 0;clear: both;visibility: hidden;}
}

/*--------------------------------------------------------------
# Box Sizing
--------------------------------------------------------------*/
*,*:after,*:before {text-rendering: optimizeLegibility;box-sizing: border-box}

/*--------------------------------------------------------------
# Links
--------------------------------------------------------------*/
a {
  @include transition();
  text-decoration: none;
  outline: 0;
  &:hover{
    text-decoration: none;
  }
}
/*--------------------------------------------------------------
# Images
--------------------------------------------------------------*/
img {max-width: 100%;vertical-align: middle;border: 0; height:auto;
  &.alignleft {margin-right: 10px;margin-bottom: 10px;}
  &.alignright {margin-left: 10px;margin-bottom: 10px;}
}
/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/
.pull-left,.alignleft,.floatleft{float:left;}
.pull-right,.alignright,.floatright{float:right;}

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
@each $heading in $headings {
  #{$heading} {
    @extend %headingstyle;
    font-size: ($font-size-upper / $font-size-base) + em;
    $font-size-upper: $font-size-upper - $font-size-dec;
  }
}
.bold,strong { @extend %bold;}
p {
  @include font ($s:15px);
  line-height: 30px; 
  margin-bottom: 20px;
}
blockquote{
  font-style: italic;
  font-family: $base-font;
  padding: 0 0 20px 40px;
}
sup,sub{
  @include position($p: relative);
  @include font($s:0.6rem);
}
sup{@include position($t:-7px);}
sub{@include position($b:-7px);}
dfn,cite,em,i {font-style: italic;}
big {font-size: 150%;}

