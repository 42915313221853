#contactPage {
  .details {
    div {
      position: relative;
      padding-left: 30px;
      i {
        position: absolute;
        left: 0;
        top: 3px;
        width: 20px;
        text-align: center;
        color: #afafaf;
        font-size: 20px;
      }
    }
  }
}

.map{
  iframe{
    max-width: 100% !important;
    width: 100%;
  }
}