/*!
Theme Name: Catch Square Themes
Theme URI: http://catchsquarethemes.com/
Author: Siddhartha Maharjan
Author URI: http://catchsquarethemes.com/
Description: A semantic, HTML5, Custom jQuery, Minimal WordPress Theme Designed and Developed by Catch Square.
Version: 2.0.1
License:Copyright &copy; 2017 Catch Square.
License URI: http://catchsquare.com/
Text Domain: Catch Square
Tags: custom-menu, sticky-post, microformats, rtl-language-support, translation-ready, full-width-template, post-formats, custom-post-type, custom-background, custom-logo, custom-menu, featured-images, threaded-comments, translation-ready
*/

/*--------------------------------------------------------------
# Reset
--------------------------------------------------------------*/

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
figure {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

:focus {
  outline: 0;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

::-moz-focus-inner {
  padding: 0;
  border: 0;
}

:focus {
  outline: 0;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

/*--------------------------------------------------------------
# Clearings
--------------------------------------------------------------*/

.clear {
  clear: both;
  display: block;
}

.clearfix:before {
  display: block;
}

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

/*--------------------------------------------------------------
# Box Sizing
--------------------------------------------------------------*/

*,
*:after,
*:before {
  text-rendering: optimizeLegibility;
  box-sizing: border-box;
}

/*--------------------------------------------------------------
# Links
--------------------------------------------------------------*/

a {
  transition: 0.3s all ease-in-out;
  text-decoration: none;
  outline: 0;
}

a:hover {
  text-decoration: none;
}

/*--------------------------------------------------------------
# Images
--------------------------------------------------------------*/

img {
  max-width: 100%;
  vertical-align: middle;
  border: 0;
  height: auto;
}

img.alignleft {
  margin-right: 10px;
  margin-bottom: 10px;
}

img.alignright {
  margin-left: 10px;
  margin-bottom: 10px;
}

/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/

.pull-left,
.alignleft,
.floatleft {
  float: left;
}

.pull-right,
.alignright,
.floatright {
  float: right;
}

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/

h1 {
  font-size: 3em;
}

h2 {
  font-size: 3em;
}

h3 {
  font-size: 3em;
}

h4 {
  font-size: 3em;
}

h5 {
  font-size: 3em;
}

h6 {
  font-size: 3em;
}

p {
  font-size: 15px;
  line-height: 30px;
  margin-bottom: 20px;
}

blockquote {
  font-style: italic;
  font-family: "Merriweather", serif;
  padding: 0 0 20px 40px;
}

sup,
sub {
  position: relative;
  font-size: 0.6rem;
}

sup {
  top: -7px;
}

sub {
  bottom: -7px;
}

dfn,
cite,
em,
i {
  font-style: italic;
}

big {
  font-size: 150%;
}

h1,
h2,
h3,
h4,
h5,
h6,
.bold,
strong {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
  margin-bottom: 20px;
}

/*--------------------------------------------------------------
# Keyframe Animations
--------------------------------------------------------------*/

@keyframes slide-down {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slide-right {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

/*--------------------------------------------------------------
# Base
--------------------------------------------------------------*/

html,
body {
  width: 100%;
}

body {
  font-family: "Merriweather", serif;
  font-size: 15px;
  font-weight: 400;
  color: #393939;
  background: #ffffff;
  margin: 0;
  line-height: 30px;
  padding: 0;
}

ul,
li {
  text-decoration: none;
  list-style: none;
  margin-bottom: 0 !important;
  padding: 0;
}

.wrapper {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto !important;
}

.btn {
  background: #065baa;
  color: #ffffff;
  border: none;
  text-transform: uppercase;
  font-size: 15px;
  cursor: pointer;
  letter-spacing: 0.5px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  border-radius: 50px;
  padding: 8px 25px;
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.08);
  margin-right: 10px;
  transition: 0.3s all ease-in-out;
}

.btn:hover {
  background: #da2b32;
  color: #ffffff;
}

.darkTheme {
  background: #065baa;
  color: #ffffff;
}

.bg-light {
  background: #f6f6f6;
}

.mainTitle {
  font-size: 30px;
  font-weight: 300;
  color: #393939;
}

.mainTitle.small {
  font-size: 25px;
}

.mainTitle span {
  color: #065baa;
  font-weight: 800;
}

.mainTitle span.block {
  display: block;
}

.mainTitle span.upper {
  text-transform: uppercase;
}

.mainTitle.light {
  color: #ffffff;
}

.mainTitle.light span {
  color: #ffffff;
}

.basePadding {
  padding-top: 80px;
  padding-bottom: 80px;
}

.basePadding.smallPadding {
  padding-top: 40px;
  padding-bottom: 40px;
}

#innerBanner {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 3px solid #065baa;
  margin-top: -60px;
}

#innerBanner .bannerWrapper {
  position: relative;
  min-height: 300px;
}

#innerBanner h1 {
  margin-bottom: auto;
  display: inline-block;
  background: #065baa;
  color: #ffffff;
  padding: 10px 20px 5px;
  font-size: 24px;
  font-weight: 700;
  position: absolute;
  bottom: 0;
}

#innerBanner .rightBar {
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: right;
}

#innerBanner .enquirybtn {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  color: #ffffff;
  padding: 10px 20px 7px;
  font-family: "Poppins", sans-serif;
  display: inline-block;
  transition: 0.3s all ease-in-out;
}

#innerBanner .enquirybtn:hover {
  background-image: linear-gradient(to right, #065baa, #065baa);
}

#innerBanner .breadcrumbBar {
  line-height: 38px;
  text-align: right;
  font-size: 14px;
}

#innerBanner .breadcrumbBar li {
  display: inline-block;
}

#innerBanner .breadcrumbBar li a {
  color: #065baa;
}

#innerBanner .breadcrumbBar li a:hover {
  text-decoration: underline;
}

#innerBanner .breadcrumbBar li:before {
  content: ">";
  display: inline-block;
  margin: 0 7px 0 5px;
}

#innerBanner .breadcrumbBar li:first-child:before {
  display: none;
}

.gradient {
  background-image: linear-gradient(to right, #065baa, #d71920);
  color: #ffffff;
}

.contentArea ul,
.contentArea ol {
  padding-left: 25px;
}

.contentArea ul li,
.contentArea ol li {
  margin-bottom: 5px;
  list-style: disc;
  line-height: 24px;
}

.styledHeading {
  color: #393939;
  font-size: 25px;
  line-height: 45px;
  margin-bottom: 0;
  font-weight: 300;
}

.styledHeading b {
  font-weight: 700;
  color: #065baa;
}

.container-small {
  max-width: 700px;
}

.responsive-TabAccordion .nav-tabs {
  display: none;
}

@media (min-width: 768px) {
  .responsive-TabAccordion .nav-tabs {
    display: flex;
  }

  .responsive-TabAccordion .card {
    border: none;
  }

  .responsive-TabAccordion .card .card-header {
    display: none;
  }

  .responsive-TabAccordion .card .collapse {
    display: block;
  }
}

@media (max-width: 767px) {
  .responsive-TabAccordion .tab-pane {
    display: block !important;
    opacity: 1;
    margin-bottom: 8px;
  }

  .responsive-TabAccordion .card-header {
    padding: 0;
    background: none;
    border: none;
  }

  .responsive-TabAccordion .card-header a {
    padding: 0.75rem 1.25rem;
    background: #065baa;
    color: #ffffff;
    display: block;
    width: 100%;
  }

  .responsive-TabAccordion .card-body h3 {
    display: none;
  }
}

#accordion .card {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #e1e1e1;
  padding: 15px 0;
}

#accordion .card .card-header {
  padding: 0 0 0;
  border: none;
  background: none;
  position: relative;
}

#accordion .card .card-header a {
  font-size: 18px;
  font-weight: 500;
  display: block;
  line-height: 26px;
  position: relative;
  padding-left: 40px;
  color: #065baa;
}

#accordion .card .card-header a:before {
  font-family: FontAwesome;
  font-weight: 900;
  content: "-";
  position: absolute;
  font-size: 28px;
  left: 0;
  top: 0;
}

#accordion .card .card-header a.collapsed {
  color: #393939;
}

#accordion .card .card-header a.collapsed:before {
  content: "+";
}

#accordion .card .card-body {
  padding: 15px 0 15px 40px;
  line-height: 30px;
  font-style: italic;
  font-size: 15px;
}

.page-content h4 {
  font-weight: 500;
  font-size: 20px;
}

input[type=text],
input[type=email],
input[type=search],
input[type=number],
input[type=email],
textarea,
select {
  background: #f5f5f5;
  border: none;
  border-radius: 0;
  padding: 8px 20px;
  font-style: italic;
  width: 100%;
  margin-bottom: 13px;
}

textarea {
  height: 150px;
}

.shadow {
  background: #ffffff;
  box-shadow: 0 0 43px rgba(0, 0, 0, 0.09) !important;
}

.paginationSection {
  text-align: center;
  padding: 50px;
}

.paginationSection ul li {
  display: inline-block;
}

.paginationSection ul li a,
.paginationSection ul li span {
  background: #ffffff;
  box-shadow: 0 0 43px rgba(0, 0, 0, 0.09) !important;
  color: #393939;
  padding: 10px 15px;
  font-family: "Poppins", sans-serif;
  display: block;
  margin: 2px;
  font-size: 14px;
  font-weight: 700;
}

.paginationSection ul li span,
.paginationSection ul li a:hover {
  background: #065baa;
  color: #ffffff;
}

.sidebar .sidebarTitle {
  margin-bottom: 10px;
  font-size: 22px;
}

.sidebar aside {
  margin-top: 50px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}

.sidebar aside:first-child {
  margin-top: 0;
}

.sidebar aside.borders {
  background: #f2f2f2;
  padding: 30px;
  border-top: 5px solid #065baa;
}

.sidebar .latestNews li {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.sidebar .latestNews li:first-child {
  border-top: none;
}

.sidebar .latestNews li a {
  color: #393939;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  padding: 8px 0;
  text-overflow: ellipsis;
}

.sidebar .latestNews li a:hover {
  color: #065baa;
}

.sidebar .advertise img {
  margin-bottom: 30px;
}

.singlePageHeading {
  background: #065baa;
  color: #ffffff;
}

.singlePageHeading h1 {
  margin-bottom: 0;
  font-size: 30px;
}

.contents strong,
.contents b,
.contentArea strong,
.contentArea b {
  margin-bottom: 5px;
  font-size: 18px;
  display: inline-block;
}

.contents ul,
.contents ol,
.contentArea ul,
.contentArea ol {
  padding-left: 25px;
  margin-bottom: 20px !important;
}

.contents ul li,
.contents ol li,
.contentArea ul li,
.contentArea ol li {
  list-style: disc;
  font-size: 15px;
  line-height: 30px;
  margin-bottom: 20px;
}

.globalTab .card-header {
  display: none;
}

.globalTab .nav-tabs {
  border: none;
}

.globalTab .nav-tabs li {
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  display: inline-block;
  margin-right: 5px;
}

.globalTab .nav-tabs li a {
  color: #393939;
  background: #dadada;
  display: block;
  padding: 8px 15px;
}

.globalTab .nav-tabs li.active a,
.globalTab .nav-tabs li:hover a {
  background: #065baa;
  color: #ffffff;
}

.globalTab .tab-content {
  border: 2px solid #dadada;
}

.masonry {
  /* Masonry container */
  column-count: 4;
  column-gap: 1em;
}

.item {
  /* Masonry bricks or child elements */
  display: inline-block;
  margin: 0 0 1em;
  width: 100%;
}

.greybg {
  background-color: #f5f5f5;
}

.textImageSection .container {
  position: relative;
  z-index: 3;
}

.textImageSection .imageText {
  background-size: 50%;
  background-position: left center;
  background-repeat: no-repeat;
  position: relative;
}

.textImageSection .imageText:before {
  content: "";
  display: block;
  position: absolute;
  right: 50%;
  top: 0;
  width: 30%;
  height: 100%;
  opacity: 0.85;
  z-index: 2;
  background-image: linear-gradient(to right, transparent, #f5f5f5);
}

.textImageSection .imageText:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background: #f5f5f5;
  opacity: 0.9;
  z-index: 1;
}

.textImageSection .textImage {
  background-size: 50%;
  background-position: right center;
  background-repeat: no-repeat;
  position: relative;
}

.textImageSection .textImage:before {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 0;
  width: 30%;
  height: 100%;
  opacity: 0.85;
  z-index: 2;
  background-image: linear-gradient(to right, #fff, transparent);
}

.textImageSection .textImage:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background: #f5f5f5;
  opacity: 0.9;
  z-index: 1;
}

.loginContent .form-group {
  margin-bottom: 7px;
}

.loginContent .form-group input.form-control {
  margin-bottom: 0;
}

#courseSingle #accordion .card {
  padding: 0;
}

#courseSingle #accordion .card .card-body {
  padding: 0;
}

#courseSingle #accordion .accordingHeading {
  font-size: 18px;
  text-transform: uppercase;
  padding: 15px;
  letter-spacing: 1px;
  color: #065baa;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}

#courseSingle #accordion .accordingHeading:hover {
  background: #f1f1f1;
}

#courseSingle #accordion table.table {
  font-size: 15px;
  font-style: normal;
}

#courseSingle #accordion table.table thead tr th {
  background: #e1e1e1;
}

/*--------------------------------------------------------------
# Bootstrap Resets
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

.topBar {
  height: 30px;
  background: #065baa;
}

#colophon {
  position: relative;
  z-index: 9;
}

#navbarNavDropdown {
  justify-content: flex-end;
}

.brandLink {
  margin-top: 15px;
}

.navbar {
  font-family: "Poppins", sans-serif;
  padding: 0;
  font-weight: 700;
  text-transform: uppercase;
}

.navbar li a {
  padding: 13px 12px !important;
}

.navbar li.active a {
  background: #065baa !important;
  color: #ffffff !important;
}

.navbar li.active,
.navbar li:hover {
  /*a{
    color: $white !important;
    background: $maincolor;
  }*/
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  background: #065baa;
  color: #ffffff;
}

.navbar-nav .dropdown-menu {
  padding: 0;
  width: 200px;
  border-radius: 0;
  margin: 0;
  border: 0;
  background: #065baa;
}

.navbar-nav .dropdown-menu a {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 13px;
  padding: 3px 12px !important;
  color: #ffffff !important;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.navbar-nav .dropdown-menu a:first-child {
  border-top: none;
}

.navbar-nav .dropdown-menu a:hover {
  background: #166bba;
  color: #ffffff !important;
}

.navbar-nav .dropdown-menu a.nav-link:hover {
  color: #ffffff !important;
}

.navbar-nav .dropdown-menu a:focus {
  color: #ffffff !important;
}

.navbar-light .navbar-toggler {
  background: #ffffff;
}

.navbar-light .navbar-nav .nav-link {
  color: #393939;
  font-size: 15px;
}

.enqury {
  position: fixed;
  top: -4px;
  right: 40px;
  z-index: 999;
}

.navbar-nav li {
  position: relative;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

.footerDetails p {
  margin-bottom: 0;
}

.footerDetails a {
  color: #393939;
  flex: 1 1 auto !important;
  display: inline-block;
}

.footerDetails a:hover {
  color: #065baa;
  text-decoration: underline;
}

.footerDetails .top {
  margin-bottom: 10px;
}

.footerDetails .top a {
  padding-right: 5px;
}

.footerDetails .bottom {
  padding-right: 20%;
}

.footerDetails .bottom a {
  margin-right: 10px;
}

.footerDetails .footdetail div {
  padding-right: 5px;
  flex: 1 1 auto !important;
}

.footerDetails .mainTitle {
  margin-bottom: 8px;
}

.footerDetails .mainTitle span {
  color: #393939;
}

.footerDetails .socialinks li {
  display: inline-block;
  margin-right: 4px;
}

.footerDetails .socialinks li a {
  border-radius: 50px;
  border: 2px solid #393939;
  display: block;
  font-size: 20px;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 34px;
}

.footerDetails .socialinks li a:hover {
  border-color: #065baa;
  background: #065baa;
  color: #ffffff;
}

.listing li {
  margin-bottom: 5px !important;
}

.listing a {
  color: #393939;
}

.listing a:hover {
  text-decoration: underline;
  color: #065baa;
}

.copyright {
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: "Poppins", sans-serif;
}

.copyright bold {
  font-weight: 700;
  color: #065baa;
}

.copyright a {
  color: #065baa;
  font-weight: 700;
}

.copyright p {
  margin-bottom: 0;
}

.fbIcon {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 99;
  max-width: 50px;
  transition: 0.3s all ease-in-out;
}

.fbIcon:hover {
  transform: rotateY(180deg);
}

/*--------------------------------------------------------------
# Home page
--------------------------------------------------------------*/

.banner {
  position: relative;
  overflow: hidden;
}

/*.owl-item:not(.active) + .owl-item.active{
  background: red;
}*/

.thumbnailSlider {
  position: relative;
  z-index: 2;
}

.bgSliderWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.bgSliderWrapper div {
  height: 100%;
}

.bgSliderWrapper .slides {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.mainbanner {
  padding: 100px 0;
}

.mainbanner .texter {
  position: relative;
  z-index: 2;
}

.mainbanner label {
  font-weight: 700;
  font-size: 18px;
}

.mainbanner h5 {
  color: #393939;
  font-size: 70px;
  text-transform: uppercase;
  font-weight: 300;
  margin: 0;
  line-height: 70px;
}

.mainbanner h5 strong {
  font-weight: 800;
  color: #065baa;
}

.mainbanner p {
  font-weight: 700;
  margin-bottom: 30px;
}

.mainbanner figure {
  position: absolute;
  right: 10%;
  height: 85%;
  z-index: 8;
  bottom: 0;
}

.mainbanner figure:after {
  width: 800px;
  height: 800px;
  background: #065baa;
  border: 30px solid #ffffff;
  border-radius: 100px;
  transform: rotate(45deg);
  content: "";
  display: block;
  position: absolute;
  left: -42%;
  bottom: -90%;
  z-index: 1;
}

.mainbanner figure:before {
  width: 500px;
  height: 40px;
  transform: rotate(45deg);
  border-radius: 80px;
  content: "";
  display: block;
  position: absolute;
  background: #065baa;
  bottom: 160px;
  right: -420px;
}

.mainbanner figure img {
  height: 100%;
  width: auto;
  position: relative;
  z-index: 2;
}

.mainContent {
  background: #065baa;
  width: 260px;
  height: 260px;
  padding: 20px;
  color: #ffffff;
  position: relative;
}

.mainContent .spacing span {
  position: absolute;
  height: 50%;
  width: 50%;
  background: rgba(255, 255, 255, 0.5);
}

.mainContent .spacing span.spaceright1 {
  right: -50%;
  bottom: 0;
}

.mainContent .spacing span.spaceright2 {
  right: -100%;
  background: #da2b32;
  opacity: 0.8;
  top: 0;
}

.mainContent .spacing span.spaceright3 {
  right: -150%;
  bottom: 0;
}

.mainContent .spacing span.spaceright4 {
  right: -200%;
  top: 0;
}

.mainContent .spacing span.spaceleft1 {
  left: -50%;
  bottom: 0;
}

.mainContent .spacing span.spaceleft2 {
  left: -100%;
  background: #da2b32;
  opacity: 0.8;
  top: 0;
}

.mainContent .spacing span.spaceleft3 {
  left: -150%;
  bottom: 0;
}

.mainContent .spacing span.spaceleft4 {
  left: -200%;
  top: 0;
}

.mainContent .slides span {
  font-size: 70px;
  opacity: 0.1;
  line-height: 90px;
  font-weight: 800;
  font-family: "Poppins", sans-serif;
}

.mainContent .slides h5 {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.1px;
  font-family: "Merriweather", serif;
  margin: 0;
  padding-right: 20%;
  font-weight: 700;
}

.mainContent .slides .owl-controls .owl-pagination {
  text-align: left;
}

.mainContent .slides .owl-controls .owl-pagination .owl-page {
  margin-right: 15px;
}

.mainContent .slides .owl-controls .owl-pagination .owl-page span {
  margin: 0;
  width: 5px;
  height: 5px;
  background: #ffffff;
}

.actionListing aside {
  display: inline-block;
  background: #ffffff;
  width: 33.33%;
  height: 100%;
  float: left;
  padding: 25px;
  position: relative;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.14);
}

.actionListing aside h3 {
  text-transform: uppercase;
  color: #393939;
  font-weight: 800;
  font-size: 24px;
  margin-bottom: 10px;
  padding-right: 40px;
}

.actionListing aside p {
  margin-bottom: 0;
}

.actionListing aside:before {
  display: block;
  content: "";
  width: 86px;
  position: absolute;
  right: 20px;
  top: 20px;
  opacity: 0.05;
  height: 90px;
}

.actionListing aside.list1:before {
  background: url(./../images/list1.png) no-repeat;
}

.actionListing aside.list2:before {
  background: url(./../images/list2.png) no-repeat;
}

.actionListing aside.list3:before {
  background: url(./../images/list3.png) no-repeat;
}

.virtualTour {
  background: url(./../images/virtualBg.png) no-repeat center #065baa;
  background-size: cover;
  color: #ffffff;
  height: 100%;
  position: relative;
  cursor: pointer;
  padding-right: 40px;
  padding-left: 130px;
  transition: 0.3s all ease-in-out;
  /*a{
    color: $white;
    display: block;
    padding: 50px 50px 50px 130px;
  }*/
}

.virtualTour img {
  position: absolute;
  left: 50px;
  top: 50%;
  transition: 0.3s all ease-in-out;
  transform: translate(0, -50%);
}

.virtualTour:hover {
  background-color: #da2b32;
}

.virtualTour h2 {
  font-size: 35px;
  text-transform: uppercase;
  font-weight: 800;
  margin: 0;
  margin-top: 25px;
  line-height: 30px;
}

.virtualTour span {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.virtualTour p {
  font-size: 14px;
  font-style: italic;
  margin-bottom: 0;
}

#aboutCollege .inner {
  padding-right: 80px;
  padding-top: 80px;
  padding-bottom: 80px;
  background: url(./../images/university.png) no-repeat right top;
  background-size: 260px;
}

#aboutCollege .category {
  display: block;
  height: 175px;
  background-size: cover;
  position: relative;
  margin-bottom: 10px;
}

#aboutCollege .category:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: #065baa;
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s all ease-in-out;
}

#aboutCollege .category h3 {
  color: #ffffff;
  font-size: 45px;
  text-transform: uppercase;
  font-weight: 800;
  margin-bottom: 0;
  line-height: 30px;
  position: absolute;
  right: 25px;
  bottom: 0;
  z-index: 2;
  transition: 0.3s all ease-in-out;
}

#aboutCollege .category:hover:before {
  background: #da2b32;
}

#aboutCollege .category:hover h3 {
  right: 50px;
}

#noticeBox {
  background: #f6f6f6;
}

.noticeListing .sectionTitle .labeling {
  line-height: 33px;
  font-weight: 700;
  font-size: 16px;
  padding-right: 30px;
}

.noticeListing .col {
  margin-bottom: 5px;
}

.noticeListing figure {
  width: 50%;
  float: left;
}

.noticeListing .content {
  width: 50%;
  float: right;
  height: 100%;
  background: #ffffff;
  padding: 50px 40px 0;
}

.noticeListing .content .date {
  font-size: 10px;
  letter-spacing: 2.4px;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  color: #acacac;
  margin-bottom: 15px;
  font-weight: 700;
}

.noticeListing .content a {
  color: #393939;
}

.noticeListing .content a:hover {
  color: #065baa;
}

.noticeListing .content h4 {
  margin-bottom: 0;
  font-size: 21px;
}

.noticeListing .styled .col:nth-child(2):after {
  width: 100px;
  height: 100px;
  background: #ffffff;
  content: "";
  display: block;
  position: absolute;
  right: -85px;
  top: -100px;
}

.noticeListing .styled.colored .col:nth-child(1):before {
  width: 100px;
  height: 100px;
  background: #da2b32;
  content: "";
  display: block;
  position: absolute;
  left: -85px;
  opacity: 0.95;
  bottom: 2px;
}

.noticeListing .styled.colored .col:nth-child(2):after {
  top: 0;
  background: #065baa;
}

.middleMenu {
  display: none;
}

.middleMenu ul li {
  flex: 1 1 auto !important;
  text-align: center;
  transition: 0.3s all ease-in-out;
}

.middleMenu ul li a {
  display: block;
  padding: 20px 0;
  color: #ffffff;
  font-size: 23px;
  font-weight: 800;
  font-family: "Poppins", sans-serif;
}

.middleMenu ul li:nth-child(1) {
  background: #065baa;
}

.middleMenu ul li:nth-child(2) {
  background: #0b6ac2;
}

.middleMenu ul li:nth-child(3) {
  background: #0b73d4;
}

.middleMenu ul li:nth-child(4) {
  background: #0d7ce2;
}

.middleMenu ul li:nth-child(5) {
  background-image: linear-gradient(to right, #2495fd, #fc5157);
}

.middleMenu ul li:nth-child(6) {
  background: #fa3940;
}

.middleMenu ul li:nth-child(7) {
  background: #ef252c;
}

.middleMenu ul li:nth-child(8) {
  background: #da2b32;
}

.middleMenu ul li:hover {
  background: #065baa;
}

#missionVision {
  background: url(./../images/visionBg.jpg) no-repeat right center;
  background-size: 35%;
}

#missionVision figure {
  min-height: 70px;
}

#missionVision h4 {
  margin-bottom: 5px;
}

#testimonials {
  background-image: url(./../images/clients.jpg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 32%;
}

#testimonials .bottomPart {
  padding-left: 20%;
  padding-right: 50px;
}

.clientsSlider .saying {
  color: #393939;
  height: 100%;
  padding: 15px;
  cursor: pointer;
}

.clientsSlider .saying .inner {
  transition: 0.3s all ease-in-out;
  background: #ffffff;
  background: rgba(255, 255, 255, 0.2);
  padding: 25px;
  color: #ffffff;
  height: 100%;
}

.clientsSlider .saying p {
  min-height: 240px;
}

.clientsSlider .saying .details figure {
  float: left;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  margin-right: 10px;
  overflow: hidden;
}

.clientsSlider .saying .details h5 {
  margin-bottom: 0;
  padding-top: 10px;
  font-size: 21px;
}

.clientsSlider .saying .details label {
  font-family: "Poppins", sans-serif;
  font-style: italic;
}

.clientsSlider .active-first .saying .inner {
  background: #ffffff;
  color: #393939;
}

#aboutCollege-back {
  background: #065baa;
}

.googleMap {
  line-height: 0;
  font-size: 0;
}

.googleMap iframe {
  position: relative;
  width: 100%;
}

.callToAction .emptyspace {
  background: #065baa;
}

/*--------------------------------------------------------------
# About page
--------------------------------------------------------------*/

.aboutusPage #aboutCollege .inner {
  padding-right: 0;
  padding-bottom: 30px;
}

#aboutTab .nav {
  background: #065baa;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
  padding: 30px 0 30px 30px;
}

#aboutTab .nav li {
  display: block;
  width: 100%;
  font-size: 20px;
  margin-bottom: 5px !important;
}

#aboutTab .nav li a {
  color: #ffffff;
  padding: 8px 15px;
  display: block;
}

#aboutTab .nav li a.active,
#aboutTab .nav li a:hover {
  background: #ffffff;
  color: #065baa;
}

.goals {
  background-position: center;
  background-size: cover;
}

/*--------------------------------------------------------------
# News Page
--------------------------------------------------------------*/

.newsList {
  background-image: linear-gradient(to right, #065baa, #da2b32);
  color: #ffffff;
  width: calc(100% - 50px);
  position: relative;
  padding-right: 33% !important;
  margin-top: 80px;
}

.newsList:first-child {
  margin-top: 0;
}

.newsList h3 {
  font-size: 28px;
}

.newsList figure {
  position: absolute;
  right: -60px;
  bottom: -50px;
  border: 15px solid #ffffff;
  max-height: 100%;
  overflow: hidden;
  max-width: 40%;
}

.newsList figure:before {
  display: inline-block;
  content: "";
  background: #065baa;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 0.3s all ease-in-out;
}

.newsList figure a {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #ffffff;
  z-index: 2;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  letter-spacing: 4px;
  text-transform: uppercase;
  opacity: 0;
  width: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.newsList .date {
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  display: block;
  margin-bottom: 15px;
  position: relative;
}

.newsList .date:after {
  display: inline-block;
  content: "";
  height: 3px;
  width: 50px;
  background: #ffffff;
  margin-left: 5px;
  position: relative;
  top: -3px;
}

.newsList .socialLinks ul,
.newsList .socialLinks label {
  display: inline-block;
}

.newsList .socialLinks label {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.newsList .socialLinks li {
  display: inline-block;
  margin-right: 2px;
}

.newsList .socialLinks li a {
  background: #ffffff;
  color: #065baa;
  display: block;
  width: 30px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
}

.newsList .socialLinks li a:hover {
  background: #da2b32;
  color: #ffffff;
}

.newsList:hover figure:before {
  opacity: 1;
}

.newsList:hover figure a {
  opacity: 1;
  letter-spacing: 2px;
}

.socialLinks ul,
.socialLinks label {
  display: inline-block;
}

.socialLinks label {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.socialLinks li {
  display: inline-block;
  margin-right: 2px;
}

.socialLinks li a {
  background: #ffffff;
  color: #065baa;
  display: block;
  width: 30px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
}

.socialLinks li a:hover {
  background: #da2b32;
  color: #ffffff;
}

.attributes {
  background: #e7e7e7;
  font-family: "Poppins", sans-serif;
  padding: 8px 0;
  text-transform: uppercase;
}

.attributes span {
  display: inline-block;
  line-height: 30px;
}

.attributes .date {
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 10px;
}

.attributes .socialLinks li a {
  background: #065baa;
  color: #ffffff;
}

.attributes .socialLinks li a:hover {
  background: #da2b32;
}

.newsDetail figure {
  margin-bottom: 30px;
}

.noticsPage #innerBanner .rightBar {
  right: auto;
  left: 0;
}

/*--------------------------------------------------------------
# team Page
--------------------------------------------------------------*/

.teamsection .headings {
  background: #065baa;
  color: #ffffff;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  padding: 15px;
  position: relative;
}

.teamsection .headings:before {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #065baa;
  content: "";
  display: inline-block;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, 0);
}

.teamsection figure {
  border-radius: 50% 50% 50% 0;
  overflow: hidden;
  margin-right: 20px;
  position: relative;
  margin-bottom: 20px;
  transition: 0.3s all ease-in-out;
}

.teamsection figure:before {
  border: 14px solid #065baa;
  content: "";
  display: block;
  position: absolute;
  left: 0;
  border-radius: 50% 50% 50% 0;
  top: 0;
  height: 100%;
  width: 100%;
  transition: 0.3s all ease-in-out;
  opacity: 0;
}

.teamsection .name {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}

.teamsection .designation {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: italic;
}

.teamsection .col-md-3,
.teamsection .col-lg-3,
.teamsection .col-md-4 {
  margin-bottom: 50px;
  transition: 0.3s all ease-in-out;
  opacity: 0.5;
}

.teamsection .col-md-3:hover,
.teamsection .col-lg-3:hover,
.teamsection .col-md-4:hover {
  opacity: 1;
}

.teamsection .col-md-3:hover figure:before,
.teamsection .col-lg-3:hover figure:before,
.teamsection .col-md-4:hover figure:before {
  opacity: 1;
}

.teamsection .col-md-3:hover .name,
.teamsection .col-md-3:hover .designation,
.teamsection .col-lg-3:hover .name,
.teamsection .col-lg-3:hover .designation,
.teamsection .col-md-4:hover .name,
.teamsection .col-md-4:hover .designation {
  color: #065baa;
}

/*--------------------------------------------------------------
# course Page
--------------------------------------------------------------*/

.courseFeature li {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  padding: 10px 15px 10px 0;
  position: relative;
}

.courseFeature li:first-child {
  border-top: none;
}

.courseFeature li i {
  margin-right: 5px;
}

.courseFeature li span {
  position: absolute;
  right: 0;
}

#courseSingle h4 {
  font-size: 22px;
}

/*--------------------------------------------------------------
# contact page
--------------------------------------------------------------*/

#contactPage .details div {
  position: relative;
  padding-left: 30px;
}

#contactPage .details div i {
  position: absolute;
  left: 0;
  top: 3px;
  width: 20px;
  text-align: center;
  color: #afafaf;
  font-size: 20px;
}

.map iframe {
  max-width: 100% !important;
  width: 100%;
}

/*--------------------------------------------------------------
# contact page
--------------------------------------------------------------*/

.messageListing .content {
  padding: 40px;
}

.messageListing .mainTitle {
  font-size: 18px;
  font-style: italic;
}

.messageListing h5 {
  font-size: 27px;
}

.messageListing figure {
  height: 100%;
  background-size: cover;
  background-position: center;
}

.messageListing .styled .col:after {
  display: none !important;
}

.messageListing .col {
  display: flex;
}

.messageListing .col:nth-child(even) figure {
  order: 2;
}

.messageListing .col:nth-child(even) .content {
  order: 1;
}

/*--------------------------------------------------------------
# Responsive css
--------------------------------------------------------------*/

/*$laptop:"screen and (max-width: 1200px)";
$desktop:"screen and (max-width: 991px)";
$tablet:"screen and (max-width: 768px)";
$mobile:"screen and (max-width: 600px)";
$midmobile:"screen and (max-width: 500px)";
$smallmobile:"screen and (max-width: 400px)";
$minimobile:"screen and (max-width: 300px)";*/

@media screen and (max-width: 1700px) {
  .enqury {
    right: 0;
    max-width: 170px;
  }
}

@media screen and (max-width: 1200px) {
  .footerDetails .socialinks li a {
    width: 25px;
    height: 25px;
    line-height: 24px;
    font-size: 14px;
  }

  .mainbanner figure {
    height: 65%;
  }

  .mainbanner figure:before {
    right: -380px;
    bottom: -10px;
  }

  .mainbanner figure:after {
    height: 580px;
    bottom: -100%;
  }

  .mainbanner h5 {
    font-size: 50px;
    line-height: 50px;
  }

  .callToAction .actionListing {
    margin-left: 0 !important;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 40px !important;
  }

  .callToAction .actionListing aside {
    padding: 25px;
  }

  .callToAction .actionListing aside h3 {
    padding: 0;
    font-size: 20pxa;
  }

  .callToAction .virtualWrapper {
    width: 100% !important;
    max-width: 100% !important;
    flex: 100%;
  }

  .callToAction .virtualWrapper .virtualTour {
    padding: 50px 50px 50px 135px;
  }

  .callToAction .virtualWrapper .virtualTour span {
    width: 100%;
    display: block;
  }

  .callToAction .virtualWrapper .virtualTour h2 {
    margin-top: 0;
    width: 100%;
  }

  .styled .col:nth-child(2)::after,
  .styled.colored .col:nth-child(1)::before,
  .styled.colored .col:nth-child(2)::after {
    display: none !important;
  }
}

@media screen and (max-width: 991px) {
  .navbar-nav .dropdown-menu {
    width: 100%;
  }

  .navbar-light .navbar-toggler {
    position: absolute;
    right: 40px;
    top: -20px;
    z-index: 9;
  }

  .navbar {
    background: #ffffff;
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 9;
    padding-right: 0;
    top: 10px;
  }

  .mainbanner {
    padding: 40px 0;
  }

  .mainbanner figure {
    height: 45%;
    right: 0;
    text-align: center;
  }

  .mainbanner figure:before,
  .mainbanner figure:after {
    display: none;
  }

  .mainContent {
    height: 170px;
  }

  .mainContent .slides span {
    font-size: 40px;
    line-height: 50px;
  }

  .mainContent .slides h5 {
    font-size: 14px;
    line-height: 22px;
  }

  #aboutCollege .inner {
    padding: 20px 0;
  }

  #missionVision {
    background: none;
  }

  .noticeListing figure {
    width: 100%;
    background: white;
    float: none;
  }

  .noticeListing figure img {
    margin: 0 auto;
    display: block;
    padding-top: 15px;
  }

  .noticeListing .content {
    width: 100%;
    height: 215px;
    float: none;
  }

  .noticeListing .content h4 {
    font-size: 18px;
  }

  .noticeListing .styled .col:nth-child(2)::after,
  .noticeListing .styled.colored .col:nth-child(1)::before,
  .noticeListing .styled.colored .col:nth-child(2)::after {
    display: none;
  }

  .noticeListing .mainTitle {
    font-size: 25px;
  }

  .noticeListing .sectionTitle .labeling {
    font-size: 12px;
  }

  .copyright .text-right,
  .copyright .col-md-12 {
    text-align: center !important;
  }
}

@media screen and (max-width: 768px) {
  .basePadding {
    padding: 40px 0;
  }

  .mainTitle {
    font-size: 20px;
  }

  .mainTitle.small {
    font-size: 18px;
  }

  #missionVision h4 {
    font-size: 20px;
  }

  #testimonials .topPart br {
    display: none;
  }

  #aboutCollege .container {
    max-width: 90%;
  }

  #aboutCollege .category {
    border-right: 5px solid #ffffff;
    width: 33.33%;
    height: 105px;
    float: left;
  }

  #aboutCollege .category h3 {
    font-size: 20px;
    line-height: 15px;
  }

  .callToAction .actionListing aside {
    width: 100%;
    margin-bottom: 10px;
    padding: 25px 105px 25px 25px;
    height: auto;
  }

  .middleMenu {
    display: none;
  }

  #missionVision {
    background: none;
  }

  .d-flex.footdetail {
    display: block !important;
    margin-top: 25px;
  }

  .footerDetails .top {
    width: 70%;
    text-align: center;
    margin: 0 auto 10px auto;
    display: none !important;
  }

  .footerDetails .bottom {
    padding-right: 0%;
    width: 65%;
    text-align: center;
    margin: 0 auto;
  }

  .footerDetails .footdetail div {
    width: 50%;
    float: left;
    min-height: 120px;
  }

  .footerDetails .footdetail div.location,
  .footerDetails .footdetail div.email {
    text-align: right;
    padding-right: 15px;
  }

  .noticeListing .sectionTitle {
    display: block !important;
  }

  .noticeListing .sectionTitle .labeling {
    margin-bottom: 20px;
    display: block;
    line-height: 22px;
    text-align: left !important;
  }

  .noticeListing figure img {
    padding-top: 0;
  }

  .noticeListing .mainTitle {
    margin-bottom: 0 !important;
  }

  .noticeListing .content {
    padding: 20px 20px 0;
    height: 175px;
  }

  #testimonials {
    background-image: none;
  }

  #testimonials .topPart {
    margin-bottom: 20px;
  }

  #testimonials .col-md-8.offset-4 {
    margin-left: 0 !important;
  }

  #testimonials .bottomPart {
    padding: 0 !important;
  }

  .clientsSlider .saying p {
    min-height: auto;
  }

  .hideResponsive {
    display: none;
  }

  .basePadding {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .aboutusPage #aboutCollege {
    padding-bottom: 0 !important;
  }

  .aboutusPage #aboutCollege .inner {
    padding-bottom: 0;
  }

  .callToAction .actionListing {
    padding-right: 15px !important;
    padding-left: 15px !important;
    padding-bottom: 0 !important;
  }

  .styledHeading {
    font-size: 18px;
    padding: 0 20px;
    line-height: 26px;
  }

  h3 {
    font-size: 1rem;
  }

  .mb-5,
  .my-5 {
    margin-bottom: 1.5rem !important;
  }

  .pt-5,
  .py-5 {
    padding-top: 1.5rem !important;
  }

  .pb-5,
  .py-5 {
    padding-bottom: 1.5rem !important;
  }

  #innerBanner h1 {
    font-size: 20px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  #innerBanner .rightBar {
    bottom: 40px;
    left: 50%;
    right: auto;
    transform: translate(-50%, 0);
  }

  #innerBanner .breadcrumbBar {
    text-align: center;
  }

  #innerBanner .enquirybtn {
    font-size: 14px;
    letter-spacing: 4px;
    padding: 7px 12px 7px;
  }

  #innerBanner .rightBar {
    width: 100%;
    text-align: center;
  }

  .newsList {
    width: 100%;
    padding: 30px !important;
    margin-top: 40px;
  }

  .newsList figure {
    position: static;
    width: 100%;
    max-width: 70%;
    margin-bottom: 30px;
  }

  .newsList figure:before,
  .newsList figure a {
    display: none;
  }

  .sidebar {
    padding-left: 15px !important;
    margin-top: 50px;
  }

  .paginationSection {
    padding: 10px;
  }

  .singlePageHeading h1 {
    font-size: 18px;
  }

  .globalTab .card-header {
    display: block;
  }

  .globalTab .tab-content {
    border: none;
  }

  .globalTab .tab-pane {
    margin-bottom: 3px !important;
  }

  .textImageSection .textImage,
  .textImageSection .imageText {
    background-image: none !important;
  }

  #innerBanner h1 {
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .brandLink img {
    max-width: 190px;
  }

  .mainbanner label {
    font-size: 14px;
  }

  .mainbanner h5 {
    font-size: 35px;
    line-height: 40px;
  }

  .mainbanner figure {
    width: 30%;
  }

  .mainbanner figure img {
    height: auto;
  }

  .mainContent {
    padding: 15px;
  }

  .mainContent .spacing {
    display: none;
  }

  .mainContent .slides span {
    font-size: 30px;
  }

  .mainContent .slides h5 {
    padding-right: 0;
  }
}

@media screen and (max-width: 500px) {
  #aboutCollege .category {
    width: 100%;
    height: 200px;
  }

  .mainbanner figure {
    display: none;
  }

  .noticeListing .content {
    height: auto;
    padding: 20px 20px 20px;
  }

  .noticeListing .content h4 {
    font-size: 14px;
  }

  .noticeListing .styled {
    display: block;
  }

  .noticeListing .styled .col {
    margin: 0 0 10px !important;
    padding: 0 20px !important;
  }

  #innerBanner h1 {
    width: 100%;
    text-align: center;
  }

  #innerBanner .container {
    padding: 0;
  }

  .teamsection figure {
    margin-right: 0;
  }
}

@media screen and (max-width: 400px) {
  .footerDetails .footdetail div {
    width: 100%;
    text-align: center !important;
    min-height: auto;
    margin-bottom: 15px;
  }
}

