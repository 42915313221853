.aboutusPage{
  #aboutCollege .inner{
    padding-right: 0;
    padding-bottom: 30px;
  }
}

#aboutTab{
  .nav{
    background: $maincolor;
    font-family: $headings-font;
    font-weight: $boldweight;
    font-size: 20px;
    color: $white;
    padding: 30px 0 30px 30px;
    li{
      display: block;
      width: 100%;
      font-size: 20px;
      margin-bottom: 5px !important;
      a{
        color: $white;
        padding: 8px 15px;
        display: block;
        &.active, &:hover{
          background: $white;
          color: $maincolor;
        }
      }
    }
  }
}

.goals{
  background-position: center;background-size: cover;
}