.newsList{
  background-image: linear-gradient(to right, $maincolor , $secondary);
  color: $white;
  width: calc(100% - 50px);
  position: relative;
  padding-right: 33% !important;
  margin-top: 80px;
  &:first-child{
    margin-top: 0;
  }
  h3{
    font-size: 28px;
  }
  figure{
    position: absolute;
    right:-60px;
    bottom: -50px;
    border:15px solid $white;
    max-height: 100%;
    overflow: hidden;
    max-width: 40%;
    &:before{
      display: inline-block;
      content: "";
      background: $maincolor;
      width: 100%;
      height: 100%;
      position: absolute;
      top:0;
      left:0;
      opacity: 0;
      @include transition()
    }
    a{
      position: absolute;
      top:50%;
      left:50%;
      color: $white;
      z-index: 2;
      font-family: $headings-font;
      font-weight: $boldweight;
      letter-spacing: 4px;
      text-transform: uppercase;
      opacity: 0;
      width: 100%;
      text-align: center;
      @include translator($x:-50%, $y:-50%)
    }
  }
  .date{
    font-weight: $boldweight;
    text-transform: uppercase;
    font-family: $headings-font;
    display: block;
    margin-bottom: 15px;
    position: relative;
    &:after{
      display: inline-block;
      content: "";
      height:3px;
      width: 50px;
      background: $white;
      margin-left: 5px;
      position: relative;
      top:-3px;
    }
  }
  .socialLinks{
    ul, label{
      display: inline-block;
    }
    label{
      font-family: $headings-font;
      font-weight: $boldweight;
    }
    li{
      display: inline-block;
      margin-right: 2px;
      a{
        background: $white;
        color: $maincolor;
        display: block;
        width: 30px;
        text-align: center;
        height: 30px;
        line-height: 30px;
        font-size: 16px;
        &:hover{
          background: $secondary;
          color: $white;
        }
      }
    }
  }
  &:hover{
    figure{
      &:before{
        opacity: 1;
      }
      a{
        opacity: 1;
        letter-spacing: 2px;
      }
    }
  }
}

.socialLinks{
  ul, label{
    display: inline-block;
  }
  label{
    font-family: $headings-font;
    font-weight: $boldweight;
  }
  li{
    display: inline-block;
    margin-right: 2px;
    a{
      background: $white;
      color: $maincolor;
      display: block;
      width: 30px;
      text-align: center;
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      &:hover{
        background: $secondary;
        color: $white;
      }
    }
  }
}
.attributes{
  background: #e7e7e7;
  font-family: $headings-font;
  padding: 8px 0;
  text-transform: uppercase;
  span{
    display: inline-block;
    line-height: 30px;
  }
  .date{
    font-weight: $boldweight;
    text-transform: uppercase;
    margin-right: 10px;
  }
  .socialLinks li a{
    background: $maincolor;
    color: $white;
    &:hover{
      background: $secondary;
    }
  }
}

.newsDetail{
  figure{
    margin-bottom: 30px;
  }
}

.noticsPage{
  #innerBanner .rightBar{
    right: auto;
    left: 0;
  }
}