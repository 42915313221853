

.messageListing{
  .content{
    padding: 40px;
  }
  .mainTitle{
    font-size:18px;
    font-style: italic;
  }
  h5{
    font-size: 27px;
  }
  figure{
    height: 100%;
    background-size: cover;
    background-position: center;
  }
  .styled{
    .col{
      &:after{
        display: none !important;
      }
    }
  }
  .col{
    display: flex;
    &:nth-child(even){
      figure{
        order:2
      }
      .content{
        order:1
      }
    }
  }
}