%floatleft{ float:left;}
%floatright{ float: right;}
%floatnone { float: none;}
%bold{@include font($w:$boldweight,$f:$headings-font);}
%headingstyle {@extend %bold;line-height: 1.3;margin-bottom: 20px;}
%halfwidth{width: 50%;}
%v-align-middle{@include position($p:absolute,$t:50%,$l:50%);@include translator(-50%,-50%);}
%textunderline{@include text($d:underline);}
%bgcover{background: center no-repeat;background-size: cover;}
%link{color: $black;&:hover{@extend %textunderline;}}

/*--------------------------------------------------------------
# Keyframe Animations
--------------------------------------------------------------*/
@include keyframes(slide-down){
  from{
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
@include keyframes(slide-right){
  from{
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}


