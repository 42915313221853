/*!
Theme Name: Catch Square Themes
Theme URI: http://catchsquarethemes.com/
Author: Siddhartha Maharjan
Author URI: http://catchsquarethemes.com/
Description: A semantic, HTML5, Custom jQuery, Minimal WordPress Theme Designed and Developed by Catch Square.
Version: 2.0.1
License:Copyright &copy; 2017 Catch Square.
License URI: http://catchsquare.com/
Text Domain: Catch Square
Tags: custom-menu, sticky-post, microformats, rtl-language-support, translation-ready, full-width-template, post-formats, custom-post-type, custom-background, custom-logo, custom-menu, featured-images, threaded-comments, translation-ready
*/

@import "imports/_reset";
@import "imports/_variables";
@import "imports/_mixins";
@import "imports/_base";
@import "imports/_placeholder";


/*--------------------------------------------------------------
# Base
--------------------------------------------------------------*/
@import "common/_global";

/*--------------------------------------------------------------
# Bootstrap Resets
--------------------------------------------------------------*/
@import "imports/_resetbootstrap";

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
@import "common/_header";

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
@import "common/_footer";

/*--------------------------------------------------------------
# Home page
--------------------------------------------------------------*/
@import "pages/_home";

/*--------------------------------------------------------------
# About page
--------------------------------------------------------------*/
@import "pages/_about";

/*--------------------------------------------------------------
# News Page
--------------------------------------------------------------*/
@import "pages/_news";

/*--------------------------------------------------------------
# team Page
--------------------------------------------------------------*/
@import "pages/_team";

/*--------------------------------------------------------------
# course Page
--------------------------------------------------------------*/
@import "pages/_courses";

/*--------------------------------------------------------------
# contact page
--------------------------------------------------------------*/
@import "pages/_contact";

/*--------------------------------------------------------------
# contact page
--------------------------------------------------------------*/
@import "pages/_message";

/*--------------------------------------------------------------
# Responsive css
--------------------------------------------------------------*/
@import "common/_responsive";










