.topBar{
  height: 30px;
  background: $maincolor;
}
#colophon{
  position: relative;
  z-index: 9
}
#navbarNavDropdown{
  justify-content: flex-end;
}
.brandLink{
  margin-top: 15px;
}
.navbar{
  font-family: $headings-font;
  padding: 0;
  font-weight: $boldweight;
  text-transform: uppercase;
  //padding-right: 30%;
  li{
    a{
      padding: 13px 12px !important;
    }
    &.active{
      a{
        background: $maincolor !important;
        color:  $white !important;
      }
    }
    &.active, &:hover{
      /*a{
        color: $white !important;
        background: $maincolor;
      }*/
    }
  }
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover{
  background: $maincolor;
  color: $white ;
}
.navbar-nav .dropdown-menu{
  padding: 0;
  width: 200px;
  border-radius: 0;
  margin: 0;
  border:0;
  //background: $white;
  background: $maincolor ;
  a{
    font-family: $headings-font;
    font-weight: $boldweight;
    font-size: 13px;
    padding: 3px 12px !important;
    //background: $white !important;
    color: $white !important;
    border-top:1px solid rgba(255,255,255,0.05);
    &:first-child{
      border-top:none;
    }
    &:hover{
      background:#166bba;
      color: $white !important;
    }
    &.nav-link{
      &:hover{
        color: $white !important;
      }
    }
    &:focus{
      color: $white !important;
    }
  }
}
.navbar-light {

  .navbar-toggler{
    background: $white;
  }
  .navbar-nav .nav-link{
    color: $text;
    font-size: 15px;
  }
}

.enqury{
  position: fixed;
  top:-4px;
  right: 40px;
  z-index: 999;
}
.navbar-nav{
  li{
    position: relative;
  }
}