.footerDetails{
  p{
    margin-bottom: 0;
  }
  a{
    //width: 49%;
    color: $text;
    flex: 1 1 auto !important;
    display: inline-block;
    &:hover{
      color: $maincolor;
      text-decoration: underline;
    }
  }
  .top{
    margin-bottom: 10px;
    a{
      padding-right: 5px;
    }
  }
  .bottom{
    padding-right: 20%;
    a{
      margin-right: 10px;
    }
  }
  .footdetail{
    div{
      padding-right: 5px;
      flex: 1 1 auto !important;
    }
  }
  .mainTitle{
    margin-bottom: 8px;
    span{
      color: $text;
    }
  }
  .socialinks{
    li{
      display: inline-block;
      margin-right: 4px;
      a{
        border-radius: 50px;
        border:2px solid $text;
        display: block;
        font-size: 20px;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 34px;
        &:hover{
          border-color: $maincolor;
          background: $maincolor;
          color: $white;
        }
      }
    }
  }
}

.listing{
  li{
    margin-bottom: 5px !important;
  }
  a{
    color: $text;
    &:hover{
      text-decoration: underline;
      color: $maincolor;
    }
  }
}

.copyright{
  padding-top:10px ;
  padding-bottom: 10px;
  font-family: $headings-font;
  bold{
    font-weight: $boldweight;
    color: $maincolor;
  }
  a{
    color: $maincolor;
    font-weight: $boldweight;
  }
  p{
    margin-bottom: 0;
  }
}


.fbIcon{
  position: fixed;
  right:20px;
  bottom: 20px;
  z-index: 99;
  max-width: 50px;
  @include transition;
  &:hover{
    transform: rotateY(180deg);
  }
}