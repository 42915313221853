.teamsection{
  .headings{
    background: $maincolor;
    color: $white;
    font-size: 20px;
    font-family: $headings-font;
    padding:15px;
    position: relative;
    &:before{
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid $maincolor;
      content: "";
      display: inline-block;
      position: absolute;
      bottom: -10px;
      left:50%;
      @include translator($x:-50%, $y:0)
    }
  }
  figure{
    border-radius: 50% 50% 50% 0;
    overflow: hidden;
    margin-right: 20px;
    position: relative;
    margin-bottom: 20px;
    @include transition;
    &:before{
      border:14px solid $maincolor;
      content: "";
      display: block;
      position: absolute;
      left:0;
      border-radius: 50% 50% 50% 0;
      top:0;
      height: 100%;
      width: 100%;
      @include transition;
      opacity:0 ;
    }
  }
  .name{
    font-family: $headings-font;
    font-size: 16px;
  }
  .designation{
    font-family: $headings-font;
    font-size: 12px;
    font-style: italic;
  }
  .col-md-3, .col-lg-3 , .col-md-4{
    margin-bottom: 50px;
    @include transition;
    opacity: 0.5;
    &:hover{
      opacity: 1;
      figure{
        &:before{
          opacity: 1;
        }
      }
      .name, .designation{
        color: $maincolor;
      }
    }
  }
}