

.courseFeature {
  li {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    padding: 10px 15px 10px 0;
    position: relative;
    &:first-child {
      border-top: none;
    }
    i{
      margin-right: 5px;
    }
    span{
      position: absolute;
      right:0
    }

  }
}

#courseSingle{
  h4{

    font-size: 22px;
  }
}