/*$laptop:"screen and (max-width: 1200px)";
$desktop:"screen and (max-width: 991px)";
$tablet:"screen and (max-width: 768px)";
$mobile:"screen and (max-width: 600px)";
$midmobile:"screen and (max-width: 500px)";
$smallmobile:"screen and (max-width: 400px)";
$minimobile:"screen and (max-width: 300px)";*/


@media screen and (max-width: 1700px){
  .enqury{
    right: 0;
    max-width:170px;
  }
}

@media #{$laptop}{
  .footerDetails .socialinks li a{
    width: 25px;
    height: 25px;
    line-height: 24px;
    font-size: 14px;
  }
  .mainbanner {
    figure{
      height: 65%;
      &:before{
        right:-380px;
        bottom:-10px;
      }
      &:after{
        height: 580px;
        bottom: -100%;
      }

    }
    h5{
      font-size: 50px;
      line-height: 50px;
    }
  }

  .callToAction{
    .actionListing{
      margin-left: 0 !important;
      flex: 0 0 100%;
      max-width: 100%;
      padding: 40px !important;
      aside {
        padding: 25px;
        h3{
          padding: 0;
          font-size: 20pxa;
        }
      }
    }
    .virtualWrapper {
      width: 100% !important;
      max-width: 100% !important;
      flex: 100%;
      .virtualTour {
        padding: 50px 50px 50px 135px ;
        span{
          width: 100%;
          display: block;
        }
        h2{
          margin-top: 0;
          width: 100%;
        }
      }
    }
  }
  .styled .col:nth-child(2)::after, .styled.colored .col:nth-child(1)::before, .styled.colored .col:nth-child(2)::after {
    display: none !important;
  }

}

@media #{$desktop}{
  .navbar-nav .dropdown-menu{
    width: 100%;
  }
  .navbar-light .navbar-toggler{
    position: absolute;
    right: 40px;
    top:-20px;
    z-index: 9;
  }
  .navbar{
    background: $white;
    position: absolute;
    width: 100%;
    left:0;
    z-index: 9;
    padding-right: 0;
    top:10px
  }
  .mainbanner{
    padding: 40px 0;
    figure{
      height:45%;
      right: 0;
      text-align: center;
      &:before, &:after{
        display: none;
      }
    }
  }
  .mainContent {
    height: 170px;
    .slides {
      span{
        font-size: 40px;
        line-height: 50px;
      }
      h5{
        font-size: 14px;
        line-height: 22px;
      }

    }
  }
  #aboutCollege .inner{
    padding: 20px 0;
  }

  #missionVision {
    background: none;
  }

  .noticeListing {
    figure {
      width: 100%;
      background: white;
      float: none;

      img {
        margin: 0 auto;
        display: block;
        padding-top: 15px;
      }
    }

    .content {
      width: 100%;
      height: 215px;
      float: none;
      h4 {
        font-size: 18px;
      }
    }

    .styled .col:nth-child(2)::after, .styled.colored .col:nth-child(1)::before, .styled.colored .col:nth-child(2)::after {
      display: none;
    }

    .mainTitle {
      font-size: 25px;
    }

    .sectionTitle .labeling {
      font-size: 12px;
    }
  }
  .copyright{
    .text-right, .col-md-12{
      text-align: center !important;
    }
  }
}


@media #{$tablet}{
  .basePadding{
    padding: 40px 0;
  }
  .mainTitle{
    font-size: 20px;
    &.small{
      font-size: 18px;
    }
  }
  #missionVision h4{
    font-size: 20px;
  }
  #testimonials .topPart{
    br{
      display: none;
    }
  }
  #aboutCollege {
    .container{
      max-width: 90%;
    }
    .category{
      border-right: 5px solid $white;
      width: 33.33%;
      height: 105px;
      float: left;
      h3{
        font-size: 20px;
        line-height: 15px;
      }
    }
  }
  .callToAction .actionListing aside{
    width: 100%;
    margin-bottom: 10px;
    padding: 25px 105px 25px 25px;
    height: auto;
  }

  .middleMenu {
    display: none;
  }

  #missionVision {
    background: none;
  }

  .d-flex.footdetail {
    display: block !important;
    margin-top: 25px;
  }

  .footerDetails .top {
    width: 70%;
    text-align: center;
    margin: 0 auto 10px auto;
    display: none !important;
  }

  .footerDetails .bottom {
    padding-right: 0%;
    width: 65%;
    text-align: center;
    margin: 0 auto;
  }
  .footerDetails {
    .footdetail {
      div {
        width: 50%;
        float: left;
        min-height: 120px;

        &.location, &.email {
          text-align: right;
          padding-right: 15px;
        }
      }
    }
  }
  .noticeListing{
    .sectionTitle{
      display: block !important;
      .labeling{
        margin-bottom: 20px;
        display: block;
        line-height: 22px;
        text-align: left !important;
      }
    }
    figure img{
      padding-top: 0;
    }
    .mainTitle{
      margin-bottom: 0 !important;
    }
    .content{
      padding: 20px 20px 0;
      height: 175px;
    }
  }
  #testimonials{
    background-image: none;
    .topPart{
      margin-bottom: 20px;
    }
    .col-md-8.offset-4{
      margin-left: 0 !important;
    }
   .bottomPart{
     padding: 0 !important;
   }
  }
  .clientsSlider .saying p{
    min-height: auto;
  }
  .hideResponsive{
    display: none;
  }
  .basePadding{
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .aboutusPage {
    #aboutCollege {
      padding-bottom: 0 !important;
      .inner{
        padding-bottom: 0;
      }
    }
  }
  .callToAction .actionListing{
    padding-right: 15px !important;
    padding-left: 15px !important;
    padding-bottom: 0 !important;
  }
  .styledHeading{
    font-size: 18px;
    padding: 0 20px;
    line-height: 26px;
  }
  h3{
    font-size: 1rem;
  }
  .mb-5, .my-5{
    margin-bottom: 1.5rem !important;
  }
  .pt-5, .py-5{
    padding-top: 1.5rem !important;
  }
  .pb-5, .py-5{
    padding-bottom: 1.5rem !important;
  }
  #innerBanner {
    h1{
      font-size: 20px;
      left: 50%;
      @include translator($x:-50%, $y:0)
    }
    .rightBar{
      bottom: 40px;
      left: 50%;
      right: auto;
      @include translator($x:-50%, $y:0)
    }
    .breadcrumbBar{
      text-align: center;
    }
    .enquirybtn{
      font-size: 14px;
      letter-spacing: 4px;
      padding: 7px 12px 7px;
    }
  }
  #innerBanner .rightBar{
    width: 100%;
    text-align: center;
  }
  .newsList{
    width: 100%;
    padding: 30px !important;
    margin-top: 40px;
    figure{
      position: static;
      width: 100%;
      max-width:70%;
      margin-bottom: 30px;
      &:before, a{
        display: none;
      }
    }
  }
  .sidebar{
    padding-left: 15px !important;
    margin-top: 50px;
  }
  .paginationSection{
    padding: 10px;
  }
  .singlePageHeading{
    h1{
      font-size:18px;
    }
  }
  .globalTab {
    .card-header{
      display: block;
    }
    .tab-content{
      border:none
    }
    .tab-pane{
      margin-bottom: 3px !important;
    }
  }
  .textImageSection {
    .textImage, .imageText{
      background-image: none !important;
    }
  }
  #innerBanner h1{
    width: 100%;
    text-align: center;
  }
}

@media #{$mobile}{
  .brandLink{
    img{
      max-width: 190px;
    }
  }
  .mainbanner {
    label{
      font-size: 14px;
    }
    h5{
      font-size: 35px;
      line-height: 40px;
    }
    figure{
      width: 30%;
      img{
        height: auto;
      }
    }

  }
  .mainContent {
    padding: 15px;
    .spacing{
      display: none;
    }
    .slides {
      span{
        font-size: 30px;
      }
      h5{
        padding-right: 0;
      }
    }
  }
}
@media #{$midmobile}{
  #aboutCollege .category{
    width: 100%;
    height: 200px;
  }
  .mainbanner{
    figure{
      display: none;
    }
  }
  .noticeListing .content {
    height: auto;
    padding: 20px 20px 20px;
    h4{
      font-size: 14px;
    }
  }
  .noticeListing .styled{
    display: block;
    .col{
      margin: 0 0 10px !important;
      padding: 0 20px !important;
    }
  }
  #innerBanner {
    h1{
      width: 100%;
      text-align: center;
    }
    .container{
      padding: 0;
    }
  }
  .teamsection figure{
    margin-right: 0;
  }
}
@media #{$smallmobile}{
  .footerDetails .footdetail div{
    width: 100%;
    text-align: center !important;
    min-height: auto;
    margin-bottom: 15px;
  }
}